import {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from 'contexts/AuthContext';
import MenuView from './MenuView';

Menu.propTypes = {
  isConfetti: PropTypes.bool,
  setIsConfetti: PropTypes.func,
  businessName: PropTypes.string
};

export default function Menu({businessName}) {
  const {currentUser, logout} = useAuthContext();
  const [error, setError] = useState();

  // Logout
  let navigate = useNavigate();
  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/');
    } catch {
      setError('Failed to log out');
    }
  }

  return (
    <MenuView
      currentUser={currentUser}
      onLogout={handleLogout}
      error={error}
      businessName={businessName}
    />
  );
}
