import {Navigate, Outlet} from 'react-router-dom';
import {LOGIN} from 'config/router/paths';
import {useAuthContext} from 'contexts/AuthContext';

export default function PrivateRoute() {
  const {currentUser} = useAuthContext();

  if (!currentUser) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <main>
      <Outlet />
    </main>
  );
}
