import {useState} from 'react';
import Masonry from '@mui/lab/Masonry';
import {Typography, Container, Box, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import PropTypes from 'prop-types';
import {headerHeight, whiteGradient, blackGradient} from 'theme/theme';
import Menu from 'views/logged/components/Menu';
import DocumentCard from './components/DocumentCard';
import {fullDocumentReference} from './fullDocumentReference.js';

AllDocuments.propTypes = {
  dark: PropTypes.bool
};

export default function AllDocuments({dark = false}) {
  const [documentTypeFilter, setDocumentTypeFilter] = useState('');

  const handleFilterChange = (event) => {
    setDocumentTypeFilter(event.target.value);
  };

  return (
    <>
      <Menu />
      <Box
        sx={{
          background: dark ? blackGradient : whiteGradient,
          color: dark ? 'white' : 'default',
          minHeight: `calc(100vh - ${headerHeight}px)`,
          p: {xs: 1, md: 3}
        }}
      >
        <Container maxWidth="xl">
          <Typography mt={2} align="center" variant="h4" color="secondary">
            <b>All documents</b>
          </Typography>
          <Typography variant="h2" align="center" paragraph mb={4}>
            {fullDocumentReference.length} GDPR Documents
          </Typography>
          <Box mb={4} display="flex" justifyContent="flex-end">
            <FormControl sx={{width: 400}}>
              <InputLabel id="Filter by document type">Filter by document type</InputLabel>
              <Select
                labelId="Filter by document type"
                id="Filter by document type"
                value={documentTypeFilter}
                label="Filter by document type"
                onChange={handleFilterChange}
              >
                <MenuItem value="Clause for contract">Clause for contract</MenuItem>
                <MenuItem value="Full contract">Full contract</MenuItem>
                <MenuItem value="Information document">Information document</MenuItem>
                <MenuItem value="Guideline">Guideline</MenuItem>
                <MenuItem value="Findings report">Findings report</MenuItem>
                <MenuItem value={null}>-</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Masonry spacing={2} columns={{xs: 1, sm: 2, md: 3, xl: 4}}>
            {fullDocumentReference
              .filter((document) =>
                documentTypeFilter ? document.documentType === documentTypeFilter : document
              )
              .map((document, i) => (
                <DocumentCard document={document} key={i} />
              ))}
          </Masonry>
        </Container>
      </Box>
    </>
  );
}
