import DoINeedADpo from 'views/logged/components/DoINeedADpo';
import Menu from 'views/logged/components/Menu';
import OnboardingSteps from 'views/logged/components/OnboardingSteps';

export default function Home() {
  return (
    <div>
      <Menu />
      <OnboardingSteps />
      <DoINeedADpo />
    </div>
  );
}
