import {useState} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GoogleIcon from '@mui/icons-material/Google';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Divider
} from '@mui/material';
// import {getAuth, createUserWithEmailAndPassword} from 'firebase/auth';
// import ReactGA from 'react-ga4';
import {useNavigate, Link} from 'react-router-dom';
import ErrorAlert from 'components/ErrorAlert';
import {LOGIN, HOME, VERIFY_EMAIL} from 'config/router/paths';
import {useAuthContext} from 'contexts/AuthContext';

export default function Register() {
  const {registerWithEmail, loginWithGoogle} = useAuthContext();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleAcceptedTermsAndConditions = (event) => {
    setAcceptedTermsAndConditions(event.target.checked);
  };

  function handleInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // const auth = getAuth();
    if (form.password !== form.confirmPassword) {
      return setError('Passwords do not match');
    }
    if (!acceptedTermsAndConditions) {
      return setError('You must check the checkbox');
    }
    try {
      setLoading(true);
      await registerWithEmail(form.email.current.value, form.password.current.value);
      // createUserWithEmailAndPassword(auth, form.email, form.password);
      navigate(VERIFY_EMAIL);
    } catch {
      setError('Failed to register');
    }
    setLoading(false);
  }

  async function handleLoginWithGoogle() {
    try {
      setLoading(true);
      await loginWithGoogle();
      // ReactGA.event({
      //   category: 'Register',
      //   action: 'Register',
      //   label: 'Register'
      // });
      navigate(HOME);
    } catch {
      setError('Failed to register');
    }
    setLoading(false);
  }

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{minHeight: '100vh'}}
        p={2}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h2" component="h1" paragraph align="center">
            Create an account
          </Typography>
          <Box mt={3}>
            <form onSubmit={handleSubmit} name="registration_form">
              <Box my={2}>
                <TextField
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  required
                  value={form.email}
                  onChange={handleInputChanged}
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="username"
                  aria-describedby="email"
                />
              </Box>
              <Box my={2}>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter your password"
                    value={form.password}
                    onChange={handleInputChanged}
                    id="password"
                    name="password"
                    aria-describedby="password"
                    autoComplete="on"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box my={2}>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel htmlFor="confirmPassword">Confirm password</InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Confirm password"
                    value={form.confirmPassword}
                    onChange={handleInputChanged}
                    autoComplete="off"
                    id="confirmPassword"
                    name="confirmPassword"
                    aria-describedby="confirmPassword"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              {error && <ErrorAlert error={error} />}
              <Box my={2}>
                <FormControlLabel
                  required
                  id="acceptTermsAndConditions"
                  onChange={handleAcceptedTermsAndConditions}
                  control={<Checkbox checked={acceptedTermsAndConditions} />}
                  label={
                    <Typography sx={{'& a': {color: 'primary.main', textDecoration: 'none'}}}>
                      I have read and I'm cool with the{' '}
                      <a
                        href="legal-hub/?businessName=GDPR.direct&companyAddress=Torres Quevedo&companyIdNumber=B95988127&companyName=AI Labs Group SL&emailForLegalPurposes=legal@GDPR.direct"
                        taget="_blank"
                      >
                        Privacy policy
                      </a>
                    </Typography>
                  }
                />
              </Box>

              <Box my={2}>
                <Button
                  disabled={loading}
                  type="submit"
                  size="large"
                  variant="contained"
                  disableElevation
                  fullWidth
                >
                  Sign up with email
                </Button>
              </Box>
            </form>

            <Divider> OR </Divider>

            <Box mt={2}>
              <Button
                endIcon={<GoogleIcon />}
                size="large"
                variant="outlined"
                sx={{borderRadius: 10, color: '#DB4437', borderColor: '#DB4437'}}
                disableElevation
                fullWidth
                onClick={handleLoginWithGoogle}
              >
                Sign up with
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                disableElevation
                component={Link}
                fullWidth
                to={LOGIN}
                size="large"
                variant="text"
                color="inherit"
                sx={{fontWeight: '400'}}
                startIcon={<ArrowBackIcon />}
              >
                Back to login
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
