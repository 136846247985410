import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Box,
  Modal,
  LinearProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';
import ShareSnackbar from 'components/ShareSnackbar';
import {headerHeight, blueGradient} from 'theme/theme';
import Menu from 'views/logged/components/Menu';

DocumentIndexView.propTypes = {
  documents: PropTypes.array.isRequired,
  openCopiedModal: PropTypes.bool.isRequired,
  handleCloseCopiedModal: PropTypes.func.isRequired,
  handleOpenCopiedModal: PropTypes.func.isRequired,
  publicDocumentsUrl: PropTypes.string.isRequired,
  internalDocumentsUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default function DocumentIndexView({
  openCopiedModal,
  handleCloseCopiedModal,
  handleOpenCopiedModal,
  documents,
  publicDocumentsUrl,
  internalDocumentsUrl,
  loading
}) {
  return (
    <Box sx={{background: blueGradient}} height="100%">
      {loading && <LinearProgress />}
      <Menu />
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          columnSpacing={4}
          alignItems="center"
          sx={{
            minHeight: `calc(100vh - ${headerHeight}px)`,
            p: {xs: 0, md: 3},
            mt: 2,
            pb: {xs: 10, md: 8}
          }}
        >
          {documents &&
            documents.map((document, i) => (
              <Grid item xs={12} md={6} key={document.title} mb={3}>
                <Card
                  variant="outlined"
                  sx={{backgroundColor: document.backgroundColor, color: document.color}}
                >
                  <CardHeader
                    sx={{
                      textAlign: 'center'
                    }}
                    title={<Typography variant="h3">{document.title}</Typography>}
                  />
                  <CardMedia
                    component="img"
                    height="200"
                    image={document.image}
                    alt={document.title}
                  />
                  <CardContent>
                    <Typography align="center">{document.description}</Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      flexDirection: {xs: 'column', md: 'row'},
                      alignItems: 'flex-end',
                      rowGap: 1,
                      columnGap: 1
                    }}
                  >
                    <Button
                      component={Link}
                      to={i === 0 ? publicDocumentsUrl : internalDocumentsUrl}
                      size="large"
                      target="_blank"
                      disableElevation
                      variant="contained"
                      fullWidth
                    >
                      See documents
                    </Button>
                    <CopyToClipboard
                      onCopy={handleOpenCopiedModal}
                      text={
                        window.location.host + (i === 0 ? publicDocumentsUrl : internalDocumentsUrl)
                      }
                    >
                      <Button
                        startIcon={<ContentCopyIcon />}
                        size="large"
                        variant="contained"
                        disableElevation
                        fullWidth
                        color="secondary"
                      >
                        Copy link
                      </Button>
                    </CopyToClipboard>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
      <Modal
        open={openCopiedModal}
        onClose={handleCloseCopiedModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {xs: '80%', md: '25%'},
            background: '#e5edea',
            borderRadius: 3,
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography align="center" id="modal-modal-title" variant="h4" color="primary">
            Copied to clipboard
          </Typography>
        </Box>
      </Modal>
      <ShareSnackbar />
    </Box>
  );
}
