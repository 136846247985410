import {Box} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {yellowGradient} from 'theme/theme';
import DocumentLayout from 'views/shared/components/DocumentLayout';
import cookiesPolicy from './docs/cookiesPolicy.md';
import employeeRecruitment from './docs/employeeRecruitment.md';
import legalNotice from './docs/legalNotice.md';
import privacyPolicy from './docs/privacyPolicy.md';

export default function PublicDocuments() {
  const [searchParams] = useSearchParams();

  const publicDocuments = [
    {
      title: 'Legal notice',
      text: legalNotice,
      whenToUse: `The Legal Notice explains to users who you are. It's a very short text with your basic company info.`,
      howToUse: `Add a link to this page everywhere where you collect personal data. Typically in the footer of your website.`,
      videoTutorial: 'https://youtu.be/zwAMfeae2wM'
    },
    {
      title: 'Privacy policy',
      text: privacyPolicy,
      whenToUse: `The Privacy Policy explains to users how you process their data. It's a long text that should be publicly available.`,
      howToUse: `Add a link to this page everywhere where you collect personal data. Typically in the footer of your website.`,
      videoTutorial: 'https://youtu.be/YBt2qrSdm5A'
    },
    {
      title: 'Cookies policy',
      text: cookiesPolicy,
      whenToUse: `The Cookies Policy explains to users what cookies are and how you make use of them.`,
      howToUse: `Add a link to this page everywhere where you use cookies. Typically in the footer of your website.`,
      videoTutorial: 'https://youtu.be/aeyiTxIiZg8'
    },
    {
      title: 'Data protection information for candidates',
      text: employeeRecruitment,
      whenToUse: `The Data protection information for candidates explains how you use candidate's data. You need it for employee recruitment processes.`,
      howToUse: `Add a link to this page on the places where you collet personal data of candidates.`,
      videoTutorial: 'https://youtu.be/-qPWRVY4Z8A'
    }
  ];

  const company = {
    companyName: searchParams.get('companyName'),
    companyAddress: searchParams.get('companyAddress'),
    companyIdNumber: searchParams.get('companyIdNumber'),
    emailForLegalPurposes: searchParams.get('emailForLegalPurposes'),
    businessName: searchParams.get('businessName')
  };

  return (
    <Box sx={{background: yellowGradient}}>
      <DocumentLayout company={company} documents={publicDocuments} title="Legal information" />
    </Box>
  );
}
