import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Collapse,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Box,
  LinearProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import {HOME, ADD_COMPANY_INFO} from 'config/router/paths';
import {headerHeight, yellowGradient, whiteGradient} from 'theme/theme';
import Menu from 'views/logged/components/Menu';

Highlight.propTypes = {
  t: PropTypes.string.isRequired
};

function Highlight(props) {
  return props.t === '' ? (
    <Typography
      component="span"
      variant="body2"
      sx={{
        display: 'inline'
      }}
    >
      <i> ____ </i>
    </Typography>
  ) : (
    <Typography
      component="span"
      variant="body2"
      sx={{
        fontWeight: 'bold',
        backgroundColor: 'secondary.main',
        display: 'inline'
      }}
      px={1}
      mx={1}
    >
      {props.t}
    </Typography>
  );
}

AddCompanyInfoView.propTypes = {
  error: PropTypes.string,
  openAlert: PropTypes.bool.isRequired,
  onOpenAlert: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

export default function AddCompanyInfoView({
  openAlert,
  onOpenAlert,
  onInputChanged,
  onSubmit,
  loading,
  form,
  error
}) {
  const [openWhatIsThis, setOpenWhatIsThis] = useState(false);

  return (
    <Box sx={{background: yellowGradient}} height="100%">
      {loading && <LinearProgress />}
      <Menu goBackTo={HOME} />
      <Container maxWidth="lg">
        <form onSubmit={onSubmit} name={ADD_COMPANY_INFO}>
          <Grid
            container
            m="auto"
            sx={{minHeight: `calc(100vh - ${headerHeight}px)`}}
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid
              alignContent="center"
              rowSpacing={0}
              item
              container
              xs={12}
              md={6}
              p={{xs: 2, md: 4}}
              sx={{background: 'white'}}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  fontSize={{xs: 30, md: 42}}
                  lineHeight={1}
                  mb={4}
                  align="center"
                  paragraph
                >
                  Add your company information
                </Typography>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Collapse in={openAlert}>
                    <Alert
                      variant="filled"
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            onOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{mb: 2}}
                    >
                      {JSON.stringify(error)}
                    </Alert>
                  </Collapse>
                </Grid>
              )}

              <Grid item xs={12} mb={2}>
                <TextField
                  label="Company name"
                  variant="standard"
                  autoComplete="Company name"
                  fullWidth
                  id="companyName"
                  name="companyName"
                  aria-describedby="companyName"
                  value={form.companyName}
                  onChange={onInputChanged}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  label="Company Address"
                  variant="standard"
                  autoComplete="Address"
                  fullWidth
                  id="companyAddress"
                  name="companyAddress"
                  aria-describedby="companyAddress"
                  value={form.companyAddress}
                  onChange={onInputChanged}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  label="Company ID number"
                  variant="standard"
                  autoComplete="ID"
                  fullWidth
                  id="companyIdNumber"
                  name="companyIdNumber"
                  aria-describedby="companyIdNumber"
                  value={form.companyIdNumber}
                  onChange={onInputChanged}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  label="Email for legal purposes"
                  variant="standard"
                  autoComplete="email"
                  fullWidth
                  id="emailForLegalPurposes"
                  name="emailForLegalPurposes"
                  aria-describedby="email"
                  value={form.emailForLegalPurposes}
                  onChange={onInputChanged}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  label="Commercial name or brand"
                  variant="standard"
                  autoComplete="company name"
                  fullWidth
                  id="businessName"
                  name="businessName"
                  aria-describedby="company name"
                  value={form.businessName}
                  onChange={onInputChanged}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <Button
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={loading}
                >
                  Save information
                </Button>
              </Grid>
            </Grid>
            <Grid
              justifyContent="center"
              display="flex"
              flexDirection="column"
              item
              xs={12}
              md={6}
              p={{xs: 2, md: 4}}
              mb={{xs: 2, md: 0}}
              sx={{background: whiteGradient}}
            >
              <Card variant="outlined">
                <CardHeader
                  sx={{backgroundColor: 'primary.main', color: 'white'}}
                  title="Here's an example"
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setOpenWhatIsThis(true);
                      }}
                    >
                      <InfoIcon fontSize="large" sx={{color: 'white'}} />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Typography gutterBottom></Typography>
                  <Typography variant="h4" gutterBottom>
                    <b>Data controller</b>
                  </Typography>
                  <Typography paragraph variant="body2">
                    The entity responsible for processing the data collected is
                    <Highlight t={form.companyName} />
                    with ID number <Highlight t={form.companyIdNumber} />
                    (hereinafter, <i>us</i> or <i>we</i>) and address at
                    <Highlight t={form.companyAddress} />, owner of{' '}
                    <Highlight t={form.businessName} />
                    and its platforms.
                  </Typography>
                  <Typography paragraph variant="body2">
                    You can exercise your rights of access, rectification, portability and deletion
                    of your data and those of limitation and opposition to its treatment by writing
                    to the email
                    <Highlight t={form.emailForLegalPurposes} />.
                  </Typography>
                  {openWhatIsThis && (
                    <Collapse in={openWhatIsThis}>
                      <Alert
                        variant="outlined"
                        severity="info"
                        mt={2}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenWhatIsThis(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <AlertTitle>What is this?</AlertTitle>
                        This is an example of how a legal page would look like with the information
                        you just provided.
                      </Alert>
                    </Collapse>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
}
