import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {IconButton, Collapse, Alert} from '@mui/material';
import PropTypes from 'prop-types';

ErrorAlert.propTypes = {
  error: PropTypes.string.isRequired,
  variant: PropTypes.string
};

export default function ErrorAlert({error, variant}) {
  const [openAlert, setOpenAlert] = useState(true);
  const [alertError, setAlertError] = useState(error);
  return (
    <Collapse in={openAlert}>
      <Alert
        variant={variant ? variant : 'filled'}
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpenAlert(false);
              setAlertError(null);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{mb: 2}}
      >
        {alertError}
      </Alert>
    </Collapse>
  );
}
