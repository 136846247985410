import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import {Box, Typography, Button, AppBar, Toolbar} from '@mui/material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import gdprDirectSmallLogoWhite from 'images/gdprDirectSmallLogoWhite.svg';
import {headerHeight, blueGradient} from 'theme/theme';

OnboardingStepsView.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  goBackTo: PropTypes.string,
  businessName: PropTypes.string
};

export default function OnboardingStepsView({goBackTo, currentUser, onLogout, businessName}) {
  return (
    <>
      <AppBar elevation={0} position="static" sx={{background: blueGradient}}>
        <Toolbar sx={{height: `${headerHeight}px`}}>
          {goBackTo && (
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Button
                size="large"
                component={Link}
                to={goBackTo}
                variant="text"
                startIcon={<ArrowBackIcon />}
                color="inherit"
              >
                Back
              </Button>
            </Box>
          )}
          {businessName && (
            <Box display="flex" flexGrow={1} alignItems="flex-end" width={{xs: '90%', md: '50%'}}>
              <Typography fontSize="1.3em" noWrap>
                <b>{businessName}</b>
              </Typography>
            </Box>
          )}
          <Box display="flex" flexGrow={goBackTo ? 0 : businessName ? 0 : 1} alignItems="flex-end">
            <Link to="/">
              <img src={gdprDirectSmallLogoWhite} height="30" display="inline" alt="logo" />
            </Link>
            <Typography
              sx={{
                textDecoration: 'none',
                color: 'white',
                display: {xs: businessName ? 'none' : 'flex', md: 'flex'}
              }}
              component={Link}
              lineHeight={1.1}
              to="/"
              fontSize={{xs: 30, md: 34}}
              ml={1}
              fontWeight="600"
            >
              GDPR
            </Typography>
            <Typography
              sx={{
                textDecoration: 'none',
                display: {xs: businessName ? 'none' : 'flex', md: 'flex'}
              }}
              component={Link}
              to="/"
              fontSize={{xs: 30, md: 34}}
              lineHeight={1.1}
              color="secondary"
              fontWeight="600"
            >
              direct
            </Typography>
          </Box>
          {!goBackTo && !businessName && (
            <>
              <Typography
                display={{xs: 'none', sm: 'block'}}
                noWrap
                mr={2}
                variant="body2"
                maxWidth="33%"
                className="userEmail"
                id="userEmail"
              >
                {currentUser.email}
              </Typography>
              <Button
                variant="outlined"
                endIcon={<LogoutIcon />}
                color="inherit"
                onClick={onLogout}
              >
                Log out
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
