// 1: Data subject
// 2: Data controller
// 3: Data processor
// 4: Data subprocessor

export const fullDocumentReference = [
  {
    title: 'Clauses for employment contract with employees',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Employees',
    reader: 'Employees',
    summary:
      'Annex to the employment contract of each personnel member in which the fundamental aspects in data protection are specified'
  },
  {
    title: 'Delivery of computer equipment to employees',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Infrastructure and facilities',
    reader: 'Employees',
    summary:
      'Annex to the employment contract of each personnel member in which the fundamental aspects are specified in relation to the equipment provided by {{CompanyName}} for the development of their tasks'
  },
  {
    title: 'Employee guideline on data processing',
    weActAs: null,
    readerActsAs: null,
    documentType: 'Guideline',
    companyArea: 'Employees',
    reader: 'Employees',
    summary:
      'Document in which the functions and obligations of the personnel are detailed and that must be taken into account to protect the data that can be accessed as a result of the daily activity of {{CompanyName}}.'
  },
  {
    title: 'Internships',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Employees',
    reader: 'Employees',
    summary:
      'Document in which the functions and obligations of the personnel are detailed and that must be taken into account to protect the data that can be accessed as a result of the daily activity of {{CompanyName}}.'
  },
  {
    title: 'Founders agreement',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Employees',
    reader: 'Shareholders',
    summary:
      'Document in which the functions and obligations of the personnel are detailed and that must be taken into account to protect the data that can be accessed as a result of the daily activity of {{CompanyName}}.'
  },
  {
    title: 'Service provision contract with suppliers who access personal data of our users',
    weActAs: 2,
    readerActsAs: 3,
    documentType: 'Full contract',
    companyArea: 'Suppliers and purchases',
    reader: 'Suppliers',
    summary:
      'Contract model issued by the company (RT) with the treatment manager (ET) when it deals with the data of which {{CompanyName}} is responsible.'
  },
  {
    title: 'Recruitment',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Employees',
    reader: 'Employees',
    summary:
      'This clause should be provided to anyone who sends their curriculum or participates in the {{CompanyName}} personnel selection processes.'
  },
  {
    title: 'Update of employee information',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Employees',
    reader: 'Employees',
    summary:
      'Form for employees to update their data. It can be delivered once a year together with the model 145 of the Treasury for the purpose of updating IRPF withholdings.'
  },
  {
    title: 'Clause for contract with customers when we access data from their users',
    weActAs: 3,
    readerActsAs: 2,
    documentType: 'Clause for contract',
    companyArea: 'Commercial and sales',
    reader: 'Customers',
    summary:
      'Clause to be included in general in the contracts in which it acts as et. Notwithstanding that obligations such as ET can be established in an independent annex.'
  },
  {
    title:
      'Short clause for contract with customers with first informative layer about data processing',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Commercial and sales',
    reader: 'Customers',
    summary:
      'Clause to be included in general in the contracts in which it acts as et. Notwithstanding that obligations such as ET can be established in an independent annex.'
  },
  {
    title: 'Short clause for contract with suppliers informing that we process their data',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Suppliers and purchases',
    reader: 'Suppliers',
    summary: 'This clause must be included in contracts with suppliers or signed independently.'
  },
  {
    title: 'Release of image rights',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Clause for contract',
    companyArea: 'Suppliers and purchases',
    reader: 'Anyone',
    summary:
      'Clause to introduce or attach in the data collection form to request the express consent of the interested party or representative of this for the treatment or publication of any type of image or recording that may affect it.'
  },
  {
    title: 'Footer for personal (one-to-one) emails',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Information document',
    companyArea: 'Commercial and sales',
    reader: 'Anyone',
    summary:
      'Brief text to include at the foot of the emails sent from the accounts and users of {{CompanyName}}.'
  },
  {
    title: 'Footer for marketing (one-to-many) emails',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Information document',
    companyArea: 'Commercial and sales',
    reader: 'Customers',
    summary:
      'Incorporate the clause into emails that contain their own advertising to customers (people who have hired company products or services). The purpose described is orientative, the company must indicate the concrete purpose to which the data of the E-mail recipient will be allocated.'
  },
  {
    title: 'Contract for suppliers who access personal data of our users',
    weActAs: 2,
    readerActsAs: 3,
    documentType: 'Full contract',
    companyArea: 'Suppliers and purchases',
    reader: 'Suppliers',
    summary:
      'Contract model issued by {{CompanyName}} (RT) with the treatment manager (ET) when it deals with the data that is responsible {{CompanyName}}.'
  },
  {
    title:
      '[Long version] Contract with customers when we process data of their users on their behalf',
    weActAs: 3,
    readerActsAs: 2,
    documentType: 'Full contract',
    companyArea: 'Commercial and sales',
    reader: 'Customers',
    summary:
      'Contract model to propose to the person in charge of treatment (RT) for its signature when {{CompanyName}} tries your data as a treatment manager (ET) - full version.'
  },
  {
    title:
      '[Short version] Contract with customers when we process data of their users on their behalf',
    weActAs: 3,
    readerActsAs: 2,
    documentType: 'Full contract',
    companyArea: 'Commercial and sales',
    reader: 'Customers',
    summary:
      'Contract model to propose to the person in charge of treatment (RT) for its signature when {{CompanyName}} tries your data as a treatment manager (ET) - full version.'
  },
  {
    title: 'Confidentiality and non-disclosure agreement',
    weActAs: 2,
    readerActsAs: 2,
    documentType: 'Full contract',
    companyArea: 'Suppliers and purchases',
    reader: 'Suppliers',
    summary:
      'Contract to establish confidentiality and professional secrecy between {{CompanyName}} and users who do not have permission to access personal data.'
  },
  {
    title: 'Legal notice',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Information document',
    companyArea: 'Commercial and sales',
    reader: 'Anyone',
    summary: ''
  },
  {
    title: 'Privacy policy (without DPO)',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Information document',
    companyArea: 'Commercial and sales',
    reader: 'Anyone',
    summary:
      'General privacy policy without DPD that {{CompanyName}} must place in a specific and visible link from all pages of the website for any user. Compulsory clause in the case of obtaining and/or containing personal data (identifying data, photographs, emails, etc.)'
  },
  {
    title: 'Privacy policy (with DPO)',
    weActAs: 2,
    readerActsAs: 1,
    documentType: 'Information document',
    companyArea: 'Commercial and sales',
    reader: 'Anyone',
    summary:
      'General privacy policy with DPD that {{CompanyName}} must place in a specific and visible link from all pages of the website for any user. Compulsory clause in the case of obtaining and/or containing personal data (identifying data, photographs, emails, etc.)'
  },
  {
    title: 'Guideline for our Data Protection Officer',
    weActAs: null,
    readerActsAs: null,
    documentType: 'Guideline',
    companyArea: 'Employees',
    reader: 'Employees',
    summary:
      'Brief informative guide on the definition of what the standard understands by DPD and the functions and obligations associated with said position. It includes a statement to be signed by the DPD that accredits: (i) the reception of the manual, (ii) that has been duly informed regarding the functions and obligations associated with their position, (iii) that knows them and (iv) in the which declares to possess the necessary knowledge for the performance of the entrusted work.'
  },
  {
    title: 'Risk analysis',
    weActAs: null,
    readerActsAs: null,
    documentType: 'Findings report',
    companyArea: 'Infrastructure and facilities',
    reader: 'Employees',
    summary:
      'Mandatory report for any RT and ET that serves to analyze the probability that there are risks in the treatment that is carried out, in order to determine the application of security measures appropriate to the planned risks and establish the need to carry out an evaluation of impact related to data protection. Prepared by Audens by the degree of technical-legal analysis that requires.'
  }
];
