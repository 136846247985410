import {Container, Grid, Typography} from '@mui/material';
import {useAuthContext} from 'contexts/AuthContext';
import acceptTerms from 'images/acceptTerms.svg';

function VerifyEmail() {
  const {currentUser} = useAuthContext();

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{minHeight: '100vh'}}
        p={2}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h3" component="h1" align="center" mb={-5}>
            Check your email
          </Typography>
          <img
            src={acceptTerms}
            width="100%"
            height="100%"
            object-fit="cover"
            overflow="hidden"
            alt="got email"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold" align="center" mt={-10}>
            We sent a verification to
          </Typography>
          <Typography variant="h5" color="secondary" paragraph fontWeight="bold" align="center">
            {currentUser?.email}
          </Typography>
          <Typography paragraph align="center">
            Follow the instruction in the email to verify your account
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default VerifyEmail;
