import {useState, useEffect} from 'react';
import {ref, get} from 'firebase/database';
// import {useNavigate} from 'react-router-dom';
import urlRegexSafe from 'url-regex-safe';
import {INTERNAL_DOCUMENTS, PUBLIC_DOCUMENTS} from 'config/router/paths';
import {useAuthContext} from 'contexts/AuthContext';
import {db} from 'firebaseConfig';
import article from 'images/article.svg';
import personalSite from 'images/personalSite.svg';
import DocumentIndexView from './DocumentIndexView';

export default function DocumentIndex() {
  const {currentUser} = useAuthContext();
  // const navigate = useNavigate();

  // const [company, setCompany] = useState(null);
  const [company, setCompany] = useState(function () {
    const companyFromLocalStorage = localStorage.getItem('company');
    return companyFromLocalStorage ? JSON.parse(companyFromLocalStorage) : null;
  });

  const [openCopiedModal, setOpenCopiedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [documentsUrl, setDocumentsUrl] = useState({
    publicDocumentsUrl: '',
    internalDocumentsUrl: ''
  });
  const documents = [
    {
      title: 'Public documents',
      description:
        'This takes you to the Legal Hub. You should share this link with everyone: in your website, marketing emails, and so on',
      image: personalSite,
      backgroundColor: '#ffffff',
      color: '#232323'
    },
    {
      title: 'Internal documents',
      description:
        'These documents are for your team only - except if someone performs an audit. It contains your company policies.',
      image: article,
      backgroundColor: '#232323',
      color: '#ffffff'
    }
  ];

  const handleOpenCopiedModal = () => {
    setOpenCopiedModal(true);
    setTimeout(() => setOpenCopiedModal(false), 1000);
  };

  const handleCloseCopiedModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCopiedModal(false);
  };

  useEffect(
    function () {
      async function fetchData() {
        try {
          setLoading(true);
          if (company === null) {
            const companyRef = ref(db, `/${currentUser.uid}/company`);
            const companySnapshot = await get(companyRef);
            setCompany(companySnapshot.val());
          }
        } catch (error) {
          setError('Could not fetch information');
        } finally {
          setLoading(false);
          setDocumentsUrl({
            publicDocumentsUrl:
              `${PUBLIC_DOCUMENTS}?businessName=${company.businessName}&companyAddress=${company.companyAddress}&companyIdNumber=${company.companyIdNumber}&companyName=${company.companyName}&emailForLegalPurposes=${company.emailForLegalPurposes}`.replace(
                urlRegexSafe
              ),
            internalDocumentsUrl:
              `${INTERNAL_DOCUMENTS}?businessName=${company.businessName}&companyAddress=${company.companyAddress}&companyIdNumber=${company.companyIdNumber}&companyName=${company.companyName}&emailForLegalPurposes=${company.emailForLegalPurposes}`.replace(
                urlRegexSafe
              )
          });
        }
      }
      fetchData();
    },
    [currentUser.uid, company]
  );

  return (
    <DocumentIndexView
      openCopiedModal={openCopiedModal}
      handleOpenCopiedModal={handleOpenCopiedModal}
      handleCloseCopiedModal={handleCloseCopiedModal}
      currentUser={currentUser}
      documents={documents}
      loading={loading}
      error={error}
      publicDocumentsUrl={documentsUrl.publicDocumentsUrl}
      internalDocumentsUrl={documentsUrl.internalDocumentsUrl}
    />
  );
}
