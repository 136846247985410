import {CssBaseline, ThemeProvider} from '@mui/material';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {
  UNDERSTAND_GDPR,
  ADD_COMPANY_INFO,
  VERIFY_EMAIL,
  LOGIN,
  REGISTER,
  CONSENT,
  EMAIL_VERIFIED,
  ONBOARDING,
  HOME,
  DOCUMENTS,
  INTERNAL_DOCUMENTS,
  PUBLIC_DOCUMENTS,
  LOGGED,
  ALL_DOCUMENTS
} from 'config/router/paths';
import {FirebaseContextProvider} from 'contexts/FirebaseContext';
import PrivateRoute from 'router/PrivateRoute';
import PublicRoute from 'router/PublicRoute';
import theme from 'theme/theme';
import EmailVerified from 'views/anonymous/components/EmailVerified';
import Login from 'views/anonymous/components/Login';
import Register from 'views/anonymous/components/Register';
import VerifyEmail from 'views/anonymous/components/VerifyEmail';
import AddCompanyInfo from 'views/logged/components/AddCompanyInfo';
import DocumentIndex from 'views/logged/components/DocumentIndex';
import Home from 'views/logged/components/Home';
import OnboardingSteps from 'views/logged/components/OnboardingSteps';
import TermsAndConditions from 'views/logged/components/TermsAndConditions';
import UnderstandGdpr from 'views/logged/components/UnderstandGdpr';
import AllDocuments from 'views/shared/components/AllDocuments';
import InternalDocuments from 'views/shared/components/InternalDocuments';
import PublicDocuments from 'views/shared/components/PublicDocuments';
import {AuthProvider} from './contexts/AuthContext';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FirebaseContextProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path={LOGGED} element={<PrivateRoute />}>
                <Route path={EMAIL_VERIFIED} element={<EmailVerified />} />
                <Route path={ONBOARDING} element={<OnboardingSteps />} />
                <Route path={HOME} element={<Home />} />
                <Route path={CONSENT} element={<TermsAndConditions />} />
                <Route path={UNDERSTAND_GDPR} element={<UnderstandGdpr />} />
                <Route path={ADD_COMPANY_INFO} element={<AddCompanyInfo />} />
                <Route path={DOCUMENTS} element={<DocumentIndex />} />
              </Route>
              <Route path="/" element={<PublicRoute />}>
                <Route index path={LOGIN} element={<Login />} />
                <Route path={REGISTER} element={<Register />} />
                <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
              </Route>
              <Route path={PUBLIC_DOCUMENTS} element={<PublicDocuments />} />
              <Route path={INTERNAL_DOCUMENTS} element={<InternalDocuments />} />
              <Route path={ALL_DOCUMENTS} element={<AllDocuments />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </FirebaseContextProvider>
    </ThemeProvider>
  );
}
