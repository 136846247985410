import {Box} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {blueGradient} from 'theme/theme';
import DocumentLayout from 'views/shared/components/DocumentLayout';
import contractWithPayrolsubcontractor from './docs/contractWithPayrolsubcontractor.md';
import employeeDataModification from './docs/employeeDataModification.md';
import footerForMarketingEmail from './docs/footerForMarketingEmail.md';
import footerForPersonalEmail from './docs/footerForPersonalEmail.md';
import textForCookiesBanner from './docs/textForCookiesBanner.md';

export default function InternalDocuments() {
  const [searchParams] = useSearchParams();

  const internalDocuments = [
    {
      title: 'Employee data modification',
      text: employeeDataModification,
      whenToUse: `Send it to employees when they want to change their information, such as address or back account number.`,
      howToUse: `Copy everything below this point and paste it into a new document.`
    },
    {
      title: 'Footer for personal emails',
      text: footerForPersonalEmail,
      whenToUse: `All emails sent by company employees should include this text.`,
      howToUse: `Copy all the text below and paste it into the footer of you emails.`
    },
    {
      title: 'Footer for marketing emails',
      text: footerForMarketingEmail,
      whenToUse: `All emails sent by the company for marketing purposes should include this text.`,
      howToUse: `Copy everything below and paste it into the footer of the emails that you send when in maketing activities, such as mailchimp email campaigns.`
    },
    {
      title: 'Text for cookies banner or pop-up',
      text: textForCookiesBanner,
      whenToUse: `Anywhere where you use cookies should allow users to see this banner`,
      howToUse: `Copy everything below and paste it into the banner where users accept or reject the use of cookies.`
    },
    {
      title: 'Contract with your HR and payroll services supplier',
      text: contractWithPayrolsubcontractor,
      whenToUse: `If you work with a company who helps you with payrols and employee matters, you must sign this wit. them`,
      howToUse: `Copy everything below and paste it into a new documents. Send it to your supplier for its signing.`
    }
  ];

  const company = {
    companyName: searchParams.get('companyName'),
    companyAddress: searchParams.get('companyAddress'),
    companyIdNumber: searchParams.get('companyIdNumber'),
    emailForLegalPurposes: searchParams.get('emailForLegalPurposes'),
    businessName: searchParams.get('businessName')
  };

  return (
    <Box sx={{background: blueGradient}}>
      <DocumentLayout
        company={company}
        documents={internalDocuments}
        title="Procedures and policies"
        dark
      />
    </Box>
  );
}
