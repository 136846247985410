import {useEffect, useState} from 'react';
import SendIcon from '@mui/icons-material/Send';
import {Container, Grid, Button, Typography, LinearProgress} from '@mui/material';
import {sendEmailVerification} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from 'contexts/AuthContext';
import {auth} from 'firebaseConfig';
import checkEmail from 'images/checkEmail.svg';

function VerifyEmail() {
  const {currentUser} = useAuthContext();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // console.log(currentUser);

  useEffect(
    function () {
      // currentUser.reload();
      try {
        setLoading(true);
        if (currentUser) {
          if (currentUser.emailVerified) {
            navigate('/');
          }
          sendEmailVerification(currentUser);
        }
      } catch (error) {
        setError('Could not fetch information');
        alert(error);
      } finally {
        setLoading(false);
      }
    },
    [currentUser, navigate]
  );

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser).catch((err) => {
      alert(err.message);
    });
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="sm">
        {error && <div>{error}</div>}
        <Grid
          container
          spacing={4}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{minHeight: '100vh'}}
          p={2}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h1" align="center" paragraph>
              Check your email
            </Typography>
            <img
              src={checkEmail}
              width="100%"
              height="100%"
              object-fit="cover"
              overflow="hidden"
              alt="check email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" align="center">
              We sent a verification to
            </Typography>
            <Typography variant="h5" color="secondary" paragraph fontWeight="bold" align="center">
              {currentUser?.email}
            </Typography>
            <Typography paragraph align="center">
              Follow the instruction in the email to verify your account
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" paragraph align="center">
              Didn't get an email?
            </Typography>
            <Button
              size="large"
              variant="contained"
              fullWidth
              disableElevation
              onClick={resendEmailVerification}
              endIcon={<SendIcon />}
            >
              Resend Email
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default VerifyEmail;
