import {useState, useEffect} from 'react';
import {update, ref, get} from 'firebase/database';
import ReactGA from 'react-ga4';
import {useNavigate} from 'react-router-dom';
import {HOME} from 'config/router/paths';
import {useAuthContext} from 'contexts/AuthContext';
import {db} from 'firebaseConfig';
import AddCompanyInfoView from './AddCompanyInfoView';

export default function AddCompanyInfo() {
  const {currentUser} = useAuthContext();
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    businessName: '',
    companyAddress: '',
    companyIdNumber: '',
    companyName: '',
    emailForLegalPurposes: ''
  });
  function handleInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  useEffect(() => {
    localStorage.setItem('company', JSON.stringify(form));
  }, [form]);

  useEffect(
    function () {
      async function fetchData() {
        try {
          setLoading(true);
          const companyRef = ref(db, `/${currentUser.uid}/company`);
          const companySnapshot = await get(companyRef);
          if (companySnapshot.exists()) {
            setForm(companySnapshot.val());
          }
        } catch (error) {
          setError('Could not fetch information');
        } finally {
          setLoading(false);
        }
      }
      fetchData();
    },
    [currentUser.uid]
  );

  const saveData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      ReactGA.event({
        category: 'Add company info',
        action: 'Add company info',
        label: 'Add company info'
      });
      localStorage.setItem('company', JSON.stringify(form));
      await update(ref(db, `/${currentUser.uid}`), {
        company: form,
        companyInfoCompletedAtTimestamp: Date.now()
      });
    } catch (error) {
      setError('Failed to save the company data');
    }
    setLoading(false);
    navigate(HOME);
  };

  return (
    <AddCompanyInfoView
      openAlert={openAlert}
      onOpenAlert={setOpenAlert}
      error={error}
      form={form}
      onInputChanged={handleInputChanged}
      loading={loading}
      onSubmit={saveData}
    />
  );
}
