import {createContext, useContext, useMemo} from 'react';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
import PropTypes from 'prop-types';

FirebaseContextProvider.propTypes = {
  children: PropTypes.object.isRequired
};

const firebaseConfig = {
  databaseURL: 'https://gdpr-direct-react-app-default-rtdb.europe-west1.firebasedatabase.app',
  apiKey: 'AIzaSyCVw9Y73z1tp2bCgu2rEj-xgvCtGm1AcL4',
  authDomain: 'gdpr-direct-react-app.firebaseapp.com',
  projectId: 'gdpr-direct-react-app',
  storageBucket: 'gdpr-direct-react-app.appspot.com',
  messagingSenderId: '969370945924',
  appId: '1:969370945924:web:eba2b7e5bbb9837bd3b5d1',
  measurementId: 'G-KQPCNZ8Y19'
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

const Context = createContext();

export function FirebaseContextProvider({children}) {
  const value = useMemo(
    () => ({
      db,
      auth
    }),
    []
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useFirebaseContext() {
  return useContext(Context);
}

export function useAuth() {
  const {auth} = useFirebaseContext();
  return auth;
}

export function useDb() {
  const {db} = useFirebaseContext();
  return db;
}
