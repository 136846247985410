import {useState} from 'react';
import {update, ref} from 'firebase/database';
import ReactGA from 'react-ga4';
import {useNavigate} from 'react-router-dom';
import {HOME} from 'config/router/paths';
import {useAuthContext} from 'contexts/AuthContext';
import {db} from 'firebaseConfig';
import addToFooterAndForm from 'images/addToFooterAndForm.svg';
import article from 'images/article.svg';
import justice from 'images/justice.svg';
// import personalSite from 'images/personalSite.svg';
// import recommendationLetter from 'images/recommendationLetter.svg';
import twoDocumentsPreview from 'images/twoDocumentsPreview.svg';
import UnderstandGdprView from './UnderstandGdprView';

const steps = [
  {
    title: `You don't need to reinvent the wheel. For most companies, GDPR compliance is the same.`,
    description: `Law consultants won't tell you this, because they charge for their consultancies. But honestly, the templates they use are identical for most of their customers. This makes sense, because it's literally the same regulation for all. But you should know that law firms basically copy/paste their templates for Privacy and Cookies Policies.`,
    toDo: null,
    img: justice
  },
  {
    title: `90% of complying with GDPR is showing documents to your customers`,
    description: `The most important thing is explaining to your users how you process their data. In other words, if you have a Privacy Policy, a Legal notice, and so on, available to your users in the right places, you are good.`,
    toDo: [
      'Add the Privacy and the Cookies policies in your footer',
      'Add a Legal notice to the footer of your website',
      'Include links to them in the forms where you collect data'
    ],
    img: addToFooterAndForm
  },
  {
    title: `It's all about the templates`,
    description: `The trick is having the right templates. You must obtain all the templates, fill them in correctly and make them available to your users. That's pretty much it.`,
    toDo: [
      'Get the right templates: Privacy policy, Legal notice...',
      'Fill them in appropiately',
      'Make them available to your users'
    ],
    img: article
  },
  {
    title:
      'You should have two types of documents: the ones that are publicly available, and your internal policies',
    description:
      'If you ever get audited, they will look for two things. Firstly, if you are making available documents such as your privacy policy. But they will also look at your protocols: documents in which you explain what you do in case of a data breach, for intance.',
    toDo: [
      'Write the external documents and make them public',
      'Write the internal protocols and keep them for your team'
    ],
    img: twoDocumentsPreview
  }
  // {
  //   title: 'The most important thing is the documents you show to your customers',
  //   description: `Most people don't get that 80% of GDPR compliance is simply having the right templates, well filled in, and available to your users.`,
  //   toDo: [
  //     'Get the right templates: Privacy policy, Legal notice',
  //     'Fill them in appropiately',
  //     'Make them available to your users'
  //   ],
  //   img: recommendationLetter
  // }
];

export default function UnderstandGdpr() {
  const {currentUser} = useAuthContext();

  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [tutorialComplete, setTutorialComplete] = useState(false);
  const [isConfetti, setIsConfetti] = useState(false);

  const maxSteps = steps.length;

  const handleNext = () => {
    setIsConfetti(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTimeout(() => setIsConfetti(false), 6000);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    activeStep === maxSteps && setTutorialComplete(true);
  };

  const navigate = useNavigate();

  function saveData() {
    try {
      setLoading(true);
      ReactGA.event({
        category: 'Completed tutorial',
        action: 'Completed tutorial',
        label: 'Completed tutorial'
      });
      update(ref(db, `/${currentUser.uid}`), {
        tutorialComplete: tutorialComplete,
        tutorialCompletedAtTimestamp: Date.now()
      });
    } catch (error) {
      setError('Failed to save the company data');
    }
    setLoading(false);
    navigate(HOME);
  }

  return (
    <UnderstandGdprView
      activeStep={activeStep}
      onClickNext={handleNext}
      onClickBack={handleBack}
      onSubmit={saveData}
      maxSteps={maxSteps}
      onStepChange={handleStepChange}
      steps={steps}
      error={error}
      loading={loading}
      isConfetti={isConfetti}
      setIsConfetti={setIsConfetti}
    />
  );
}
