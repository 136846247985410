import {useState, useEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import {Snackbar, Typography, IconButton} from '@mui/material';
import PropTypes from 'prop-types';

ShareSnackbar.propTypes = {
  position: PropTypes.string
};

export default function ShareSnackbar({position}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(function () {
    setTimeout(() => setOpenSnackbar(true), 666);
  }, []);
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <Snackbar
      open={openSnackbar}
      onClose={handleSnackbarClose}
      anchorOrigin={{vertical: 'bottom', horizontal: position ? position : 'center'}}
      message={
        <Typography align="center" fontWeight="bold" fontSize={23}>
          Find this useful?
        </Typography>
      }
      action={
        <>
          <IconButton
            target="_blank"
            href={`https://www.linkedin.com/shareArticle?mini=true&url=https://app.gdpr.direct/legal-hub/?businessName=Dunder%20Mifflin%20Paper%20Co&title=You have%20to%20see%20this%20%20GDPR%20compliance&summary=You%20are%20about%20to%20owe%20me%20one.</u>`}
            aria-label="share"
            color="primary"
          >
            <LinkedInIcon sx={{fontSize: {xs: 50, md: 60}}} />
          </IconButton>
          <IconButton
            aria-label="share"
            color="secondary"
            target="_blank"
            href={`mailto:%20?subject=You have to see this. GDPR compliance? Problem solved&body=</br>You are about to owe me one. Check this out: <u>https://app.gdpr.direct</u>.It creates a repository of all the legal documents you need, ready for the footer of your website. <b>And it is free</b>. Look at what it's done for us: <u>https://app.gdpr.direct/legal-hub/?businessName=Dunder%20Mifflin%20Paper%20Co.</u>.</br></br>It also creates internal policies and templates for contracts with suppliers: <u>https://app.gdpr.direct/policies-and-procedures/?businessName=Dunder%20Mifflin%20Paper%20Co.</u>`}
          >
            <MailIcon sx={{fontSize: {xs: 50, md: 60}}} />
          </IconButton>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{p: 0.5}}
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    />
  );
}
