export const LOGIN = '/';
export const REGISTER = '/register';
export const RECOVER_PASSWORD = '/recover-password';
export const VERIFY_EMAIL = '/verify-email';

export const LOGGED = '/private';
export const ONBOARDING = `${LOGGED}/onboarding`;
export const EMAIL_VERIFIED = `${LOGGED}/email-verified`;
export const HOME = `${LOGGED}/home`;
export const CONSENT = `${LOGGED}/terms-and-conditions`;
export const ADD_COMPANY_INFO = `${LOGGED}/add-company-info`;
export const LEGAL = `${LOGGED}/legal`;
export const UNDERSTAND_GDPR = `${LOGGED}/quick-tutorial-about-GDPR`;

export const DOCUMENTS = `${LOGGED}/documents`;

export const INTERNAL_DOCUMENTS = `/policies-and-procedures/`;
export const PUBLIC_DOCUMENTS = `/legal-hub/`;
export const ALL_DOCUMENTS = `/all-documents`;
