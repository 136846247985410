import {Paper} from '@mui/material';
import PropTypes from 'prop-types';

StyledPaper.propTypes = {
  children: PropTypes.object.isRequired,
  dark: PropTypes.bool.isRequired,
  seeAsLoggedIn: PropTypes.bool.isRequired
};

export default function StyledPaper({dark, seeAsLoggedIn, children}) {
  return (
    <Paper
      id="google_translate_element"
      sx={{
        px: {xs: 2, md: 4},
        borderColor: dark ? 'secondary.main' : 'primary.main',
        borderRadius: seeAsLoggedIn && '0  0 10px 10px ',
        article: {
          code: {
            border: 1,
            fontStyle: 'italic',
            borderColor: 'primary.main',
            px: 1,
            py: '1px',
            borderRadius: 1
          },
          h1: {
            fontSize: '2rem',
            lineHeight: 1.3
          },
          h2: {
            fontSize: '1.6rem',
            color: 'primary.main',
            mt: 4
          },
          h3: {
            fontSize: '1.2rem',
            color: 'secondary.main',
            mt: 3
          },
          a: {
            color: 'primary.main'
          },
          '& p, ol': {
            textAlign: 'justify',
            fontSize: '1rem'
          },
          li: {
            mb: 1
          },
          '& ::marker': {
            color: 'primary.main',
            fontWeight: 'bold'
          },
          table: {
            marginBottom: 4,
            borderSpacing: 0,
            fontSize: 15
          },
          thead: {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText'
          },
          th: {
            padding: 1.5
          },
          td: {
            paddingY: 0.5,
            paddingX: 1,
            border: 0.5,
            borderColor: 'primary.light'
          },
          hr: {
            borderColor: 'primary.light',
            borderWidth: 0.5,
            mt: 4,
            mb: 0
          }
        }
      }}
      variant="outlined"
    >
      {children}
    </Paper>
  );
}
