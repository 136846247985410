import {createTheme} from '@mui/material/styles';

const blue = '#6074cb';
const lightBlue = '#6c86ff';
const darkBlue = '#4054ad';
const yellow = '#f2b434';
export const blueGradient = 'linear-gradient(45deg, #3f51b5, #6074cb, #3f51b5)';
export const yellowGradient = 'linear-gradient(45deg, #f4c35b, #f5c664, #f4c35b)';
export const whiteGradient = 'linear-gradient(45deg, #cbdaec, #fafafa, #ebf4ff)';
export const blackGradient = 'linear-gradient(45deg, #232323, #323232, #232323)';
export const headerHeight = 72;

const defaultTheme = createTheme();
const {breakpoints} = defaultTheme;

const options = {
  palette: {
    mode: 'light',
    primary: {
      main: blue,
      dark: darkBlue,
      light: lightBlue
    },
    secondary: {
      main: yellow,
      contrastText: '#232323'
    }
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
    body1: {
      fontSize: '19px'
    },
    body2: {
      fontSize: '16px'
    },
    h1: {
      fontWeight: 'bold',
      lineHeight: 1
    },
    h2: {
      fontWeight: 'bold',
      lineHeight: 0.95,
      [breakpoints.down('sm')]: {
        fontSize: '3rem'
      }
    },
    h3: {
      fontWeight: 'bold',
      lineHeight: 0.95
    },
    h4: {
      fontWeight: 'bold',
      lineHeight: 0.95
      // [breakpoints.down('sm')]: {
      //   fontSize: '1.3rem'
      // }
    },
    button: {
      textTransform: 'none'
    },
    a: {
      textDecoration: 'none'
    }
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.8rem',
          fontWeight: 'bold',
          lineHeight: 1,
          '&.Mui-active': {
            color: blue,
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '32px',
          height: '32px',
          marginRight: 5
        },
        text: {
          fontSize: 16,
          fontWeight: 'bold'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderColor: '#cacaca'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1.2,
          paddingTop: 14,
          paddingBottom: 14
        },
        sizeLarge: {
          fontSize: '1.2rem',
          fontWeight: 'bold'
        },
        containedPrimary: {
          background: blueGradient
        },
        containedSecondary: {
          background: yellowGradient
        }
      }
    }
  }
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: lightBlue,
      light: blue,
      contrastText: '#000'
    },
    secondary: {
      main: yellow,
      contrastText: '#000'
    }
  },
  typography: options.typography
});

export default createTheme(options);
