// import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  Divider,
  Grid,
  Typography,
  Container,
  Button,
  Box,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {ONBOARDING} from 'config/router/paths';
import {headerHeight, whiteGradient} from 'theme/theme';
import Coolfetti from 'views/logged/components/Coolfetti';
import Menu from 'views/logged/components/Menu';

UnderstandGdprView.propTypes = {
  activeStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  onStepChange: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isConfetti: PropTypes.bool,
  setIsConfetti: PropTypes.func
};

export default function UnderstandGdprView({
  activeStep,
  maxSteps,
  onStepChange,
  steps,
  onClickNext,
  onClickBack,
  onSubmit,
  isConfetti,
  setIsConfetti
}) {
  return (
    <Box sx={{background: whiteGradient}}>
      {isConfetti && <Coolfetti setIsConfetti={setIsConfetti} />}
      <Menu goBackTo={ONBOARDING} setIsConfetti={setIsConfetti} isConfetti={isConfetti} />
      <Container maxWidth="lg">
        <SwipeableViews index={activeStep} onChangeIndex={onStepChange} enableMouseEvents>
          {steps.map((step, i) => (
            <Grid
              key={i}
              container
              justifyContent="center"
              alignItems="flex-start"
              sx={{height: `calc(93vh - ${headerHeight}px)`, p: {xs: 1, md: 3}}}
            >
              <Grid item xs={12}>
                <Typography fontSize="1.5em" gutterBottom variant="overtext" color="primary">
                  <b>
                    Lesson {i + 1} of {steps.length}
                  </b>
                </Typography>

                <Typography variant="h2" gutterBottom>
                  {step.title}
                </Typography>
                <Box mb={2}>
                  <Divider />
                </Box>

                <Grid item container alignItems="flex-start" direction="row-reverse">
                  <Grid item xs={12} md={7}>
                    <Typography mt={3} variant="h5" paragraph color="secondary" fontWeight="bold">
                      What does that mean?
                    </Typography>
                    <Typography paragraph>{step.description}</Typography>
                    {step.toDo && (
                      <>
                        <Typography mt={3} variant="h5" color="secondary" fontWeight="bold">
                          So how do you achieve that?
                        </Typography>

                        <List>
                          {step.toDo.map((li, i) => (
                            <ListItem key={i} divider={i !== step.toDo.length - 1}>
                              <Typography mr={2} color="primary" fontSize={23} lineHeight={1}>
                                <b>{i + 1}.</b>
                              </Typography>
                              <ListItemText primary={<Typography>{li}</Typography>} />
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} pr={2} pt={1}>
                    <img src={step.img} alt={step.facet} width="100%" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </SwipeableViews>
        <MobileStepper
          sx={{height: '8vh'}}
          elevation={4}
          variant="progress"
          steps={maxSteps}
          activeStep={activeStep}
          nextButton={
            activeStep + 1 === maxSteps ? (
              <Button
                disableElevation
                size="large"
                onClick={onSubmit}
                variant="contained"
                endIcon={<DoneAllIcon />}
              >
                Finish
              </Button>
            ) : (
              <Button
                size="large"
                onClick={onClickNext}
                variant="outlined"
                endIcon={<KeyboardArrowRight />}
              >
                Next
              </Button>
            )
          }
          backButton={
            <Button
              startIcon={<KeyboardArrowLeft />}
              size="large"
              variant="outlined"
              onClick={onClickBack}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
          }
        />
      </Container>
    </Box>
  );
}
