import PropTypes from 'prop-types';
import Confetti from 'react-confetti';

Coolfetti.propTypes = {
  onConfettiComplete: PropTypes.func,
  confettiSource: PropTypes.object,
  height: PropTypes.number
};

export default function Coolfetti({onConfettiComplete, confettiSource, height}) {
  // const stopConfetti = () => {
  //   setIsConfetti(false);
  // };

  return (
    <Confetti
      confettiSource={confettiSource}
      height={height}
      // width={'100%'}
      initialVelocityX={15}
      initialVelocityY={25}
      gravity={0.2}
      wind={0.1}
      recycle={false}
      tweenDuration={1000}
      numberOfPieces={500}
      colors={['#6074cb', '#f5c664', 'goldenrod']}
      onConfettiComplete={onConfettiComplete}
    />
  );
}
