import {createContext, useContext, useState, useEffect, useMemo, useCallback} from 'react';
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import PropTypes from 'prop-types';
// import {useAuth} from './FirebaseContext';
import {auth} from 'firebaseConfig';

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired
};

const AuthContext = createContext();

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // const auth = useAuth();

  const registerWithEmail = useCallback(function (auth, email, password) {
    return auth.createUserWithEmailAndPassword(auth, email, password);
  }, []);

  const loginWithEmail = useCallback(function (auth, email, password) {
    return auth.signInWithEmailAndPassword(auth, email, password);
  }, []);

  const logout = useCallback(function () {
    return auth.signOut();
  }, []);

  const resetPassword = useCallback(function (auth, email) {
    return auth.sendPasswordResetEmail(email);
  }, []);

  const loginWithGoogle = useCallback(function () {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      localStorage.setItem('user', JSON.stringify(currentUser));
      setLoading(false);
    });
    return unsubscribe;
  }, [currentUser]);

  const value = useMemo(
    () => ({
      currentUser,
      registerWithEmail,
      loginWithGoogle,
      loginWithEmail,
      logout,
      resetPassword
    }),
    [currentUser, registerWithEmail, loginWithGoogle, loginWithEmail, logout, resetPassword]
  );

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
