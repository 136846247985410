import {useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Button,
  IconButton
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import controllerIcon from 'images/controllerIcon.svg';
import processorIcon from 'images/processorIcon.svg';
import subjectIcon from 'images/subjectIcon.svg';

StyledListItem.propTypes = {
  person: PropTypes.number.isRequired
};

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired
};

function StyledListItem(props) {
  const entity = props.person;
  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar
          src={
            entity === 1 || entity === 1 + 4
              ? subjectIcon
              : entity === 2 || entity === 2 + 4
              ? controllerIcon
              : entity === 3 || entity === 3 + 4
              ? processorIcon
              : 'null'
          }
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="body2">
            {entity < 5 ? 'Use when we act as' : 'Use when the reader acts as'}
          </Typography>
        }
        secondary={
          <Typography
            fontWeight="bold"
            color={
              entity === 1 || entity === 1 + 4
                ? 'inherit'
                : entity === 2 || entity === 2 + 4
                ? 'primary'
                : entity === 3 || entity === 3 + 4
                ? 'secondary'
                : 'secondary'
            }
          >
            {entity === 1 || entity === 1 + 4
              ? 'Data subject'
              : entity === 2 || entity === 2 + 4
              ? 'Data controller'
              : entity === 3 || entity === 3 + 4
              ? 'Data processor'
              : 'Data subprocessor'}
          </Typography>
        }
      />
    </ListItem>
  );
}

const ExpandMore = styled((props) => {
  const {expand, ...other} = props;
  return (
    <IconButton
      {...other}
      color="primary"
      sx={{width: 50, height: 50, border: 'solid 1px', borderColor: 'primary.main'}}
    />
  );
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function DocumentCard({document}) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Paper variant="outlined" sx={{borderRadius: 3, overflow: 'hidden'}}>
      <Box sx={{px: 2, py: 1, backgroundColor: 'primary.main'}}>
        <Typography variant="overline" lineHeight={0} sx={{color: 'white'}}>
          Document title
        </Typography>
        <Typography variant="h5" lineHeight={1.15} gutterBottom sx={{color: 'white'}}>
          <span style={{marginRight: 5}} role="img">
            📄
          </span>
          <b>{document.title}</b>
        </Typography>
      </Box>
      <Divider />
      <List dense sx={{px: 1}}>
        <ListItem divider>
          <ListItemText
            disableTypography
            primary={<Typography variant="body2">Document type</Typography>}
            secondary={<Typography fontWeight="bold">{document.documentType}</Typography>}
          />
        </ListItem>
        <ListItem divider>
          <ListItemText
            disableTypography
            primary={<Typography variant="body2">Intended reader</Typography>}
            secondary={<Typography fontWeight="bold">{document.reader}</Typography>}
          />
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <>
            {document.weActAs && <StyledListItem person={document.weActAs} />}
            {document.weActAs && <StyledListItem person={document.readerActsAs + 4} />}

            <ListItem divider>
              <ListItemText
                disableTypography
                primary={<Typography variant="body2">Company area</Typography>}
                secondary={<Typography fontWeight="bold">{document.companyArea}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <Typography fontWeight="bold" gutterBottom>
                    Summary
                  </Typography>
                }
                secondary={<Typography variant="body2">{document.summary}</Typography>}
              />
            </ListItem>
          </>
        </Collapse>
      </List>
      {!expanded && (
        <Box
          sx={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
            height: 150,
            marginTop: '-150px',
            position: 'relative',
            zIndex: 1
          }}
        ></Box>
      )}
      <Box sx={{px: 2, pb: 2}} display="flex" alignItems="center">
        <Button sx={{mr: 1}} size="large" disableElevation variant="outlined" fullWidth>
          Use this document
        </Button>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
    </Paper>
  );
}
