import {useState} from 'react';
import {ThemeProvider} from '@mui/material';
import {darkTheme} from 'theme/theme';
import DoINeedADpoView from './DoINeedADpoView';

// Do I need a Data Protection Officer (DPO)?
// https://ico.org.uk/for-organisations/does-my-organisation-need-a-data-protection-officer-dpo/

export default function DoINeedADpo() {
  const [form, setForm] = useState({
    publicAuthority: null,
    largeScale: null,
    specialCategory: null
  });

  function handleInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <DoINeedADpoView form={form} onInputChanged={handleInputChanged} />
    </ThemeProvider>
  );
}
