import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired
};

export default function VideoPlayer({url}) {
  return (
    <Box sx={{position: 'relative', paddingTop: '56.25%'}}>
      <ReactPlayer
        loop
        controls
        muted
        pip
        url={url}
        width="100%"
        height="100%"
        style={{
          borderRadius: 8,
          overflow: 'auto',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      />
    </Box>
  );
}
