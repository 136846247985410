import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import {
  Grid,
  Box,
  Typography,
  Container,
  Button,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  LinearProgress,
  IconButton,
  Snackbar
} from '@mui/material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import setupWizard from 'images/setupWizard.svg';
import {headerHeight, whiteGradient} from 'theme/theme';

OnboardingStepsView.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  openSnackbar: PropTypes.bool.isRequired,
  handleSnackbarClose: PropTypes.func.isRequired
};

export default function OnboardingStepsView({
  loading,
  steps,
  activeStep,
  openSnackbar,
  handleSnackbarClose
}) {
  return (
    <Box sx={{background: whiteGradient}}>
      {loading && <LinearProgress />}
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{minHeight: `calc(75vh - ${headerHeight}px)`}}
          p={{xs: 1, md: 4}}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Get started</Typography>
            <Typography paragraph>You are three steps away</Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>
                    {step.label}
                    {index === 0 && activeStep > 0 && (
                      <IconButton
                        size="small"
                        component={Link}
                        to={step.linkTo}
                        sx={{ml: 1, color: 'primary.main', background: 'white'}}
                        aria-label="edit"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </StepLabel>
                  <StepContent>
                    <Typography paragraph>{step.description}</Typography>
                    {step.cta && (
                      <Button
                        variant="contained"
                        size="large"
                        component={Link}
                        to={step.linkTo}
                        color="secondary"
                        sx={{mt: 1, mb: 1}}
                        endIcon={<ChevronRightIcon />}
                      >
                        {step.cta}
                      </Button>
                    )}
                    {index !== steps.length - 1 && (
                      <Box sx={{mt: 1, mb: 2}}>
                        <Box display="flex" alignItems="center">
                          <Button
                            variant="outlined"
                            endIcon={<LockIcon />}
                            disabled
                            sx={{
                              mr: 1.5,
                              color: '#cacaca',
                              borderColor: '#cacaca',
                              fontSize: 16,
                              paddingX: 3
                            }}
                          >
                            Next
                          </Button>
                          {index !== steps.length - 1 && (
                            <Typography color="#cacaca" variant="body2" lineHeight={1}>
                              Complete this step first
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={6} sx={{display: {xs: 'none', md: 'block'}}}>
            <img
              src={setupWizard}
              width="100%"
              height="100%"
              object-fit="cover"
              overflow="hidden"
              alt="login"
            />
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={openSnackbar} onClose={handleSnackbarClose}>
        <Typography>Cositas</Typography>
      </Snackbar>
    </Box>
  );
}
