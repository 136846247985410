import {useState} from 'react';
import GoogleIcon from '@mui/icons-material/Google';
// import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  Grid,
  // FormControl,
  // FormControlLabel,
  // InputLabel,
  // Input,
  // Checkbox,
  Box,
  Button,
  Typography,
  MobileStepper,
  // TextField,
  // InputAdornment,
  IconButton
  // Divider
} from '@mui/material';
import PropTypes from 'prop-types';
// import {Link} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import ErrorAlert from 'components/ErrorAlert';
// import {REGISTER, ONBOARDING, RECOVER_PASSWORD} from 'config/router/paths';
import addToFooter from 'images/addToFooter.svg';
import gdprDirectSmallLogo from 'images/gdprDirectSmallLogo.svg';
import recommendationLetter from 'images/recommendationLetter.svg';
import setupWizard from 'images/setupWizard.svg';
import {whiteGradient, blueGradient} from 'theme/theme';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

LoginView.propTypes = {
  openAlert: PropTypes.bool.isRequired,
  setOpenAlert: PropTypes.func.isRequired,
  onLoginWithGoogle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onClickShowPassword: PropTypes.func.isRequired,
  onMouseDownPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

const carouselItems = [
  {
    img: setupWizard,
    claim: 'Ensure compliance, save time and effort, improve consistency and reduce risk'
  },
  {img: recommendationLetter, claim: 'Implement effective GDPR-compliance in less than 10 minutes'},
  {img: addToFooter, claim: 'Get all the documents you need and add them to your platforms'}
];

const legalHubAnchor =
  'http://app.gdpr.direct/legal-hub/?businessName=GDPR.direct&companyAddress=Torres%20Quevedo&companyIdNumber=B95988127&companyName=Ai%20Labs%20Group%20SL&emailForLegalPurposes=legal@gdpr.direct';
const legalLinks = [
  {title: 'Privacy policy', a: legalHubAnchor},
  {title: 'Cookies policy', a: legalHubAnchor},
  {title: 'Legal notice', a: legalHubAnchor}
];

export default function LoginView({
  error,
  // openAlert,
  onLoginWithGoogle
  // setOpenAlert,
  // onSubmit,
  // form,
  // onInputChanged,
  // onClickShowPassword,
  // onMouseDownPassword,
  // showPassword,
  // loading
}) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = carouselItems.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid
      container
      justifyContent="center"
      direction="row-reverse"
      alignItems="stretch"
      sx={{height: '100vh'}}
    >
      <Grid item xs={12} md={6} sx={{background: whiteGradient}}>
        <Box
          sx={{
            height: {xs: 500, md: '100vh'},
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'center',
            py: {xs: 4, lg: 6},
            px: {xs: 2, lg: 6}
          }}
        >
          <Box
            display="flex"
            mt={2}
            ml={-3}
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Box sx={{width: {xs: '90px', sm: '111px'}}}>
              <img src={gdprDirectSmallLogo} width="100%" alt="logo" />
            </Box>
            <Box ml={1}>
              <Typography
                fontSize={{xs: '3rem', sm: '3.6rem'}}
                variant="h2"
                component="h1"
                lineHeight={1.1}
                color="primary"
              >
                GDPR
              </Typography>
              <Typography
                fontSize={{xs: '2.5rem', sm: '3.2rem'}}
                variant="h2"
                component="h1"
                color="secondary"
                fontFamily="Rockwell"
                lineHeight={0.5}
              >
                direct
              </Typography>
            </Box>
          </Box>
          <Typography align="center" variant="h3" mt={4}>
            Welcome
          </Typography>
          <Typography align="center" paragraph>
            Log in or create a new account.
          </Typography>
          {error && (
            <Box mt={3}>
              <ErrorAlert error={error} />
            </Box>
          )}
          <Box>
            {/* <form onSubmit={onSubmit} name="login_form">
            <Box my={2}>
              <TextField
                label="Email"
                variant="standard"
                autoComplete="email"
                fullWidth
                id="email"
                name="email"
                aria-describedby="email"
                value={form.email}
                onChange={onInputChanged}
              />
            </Box>
            <Box my={1} mb={2}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  autoComplete="password"
                  name="password"
                  onChange={onInputChanged}
                  value={form.password}
                  type={showPassword ? 'text' : 'password'}
                  aria-describedby="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onClickShowPassword}
                        onMouseDown={onMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={<Checkbox color="primary" name="remember" />}
                  label={<Typography variant="body2">Remember</Typography>}
                />
              </Grid>
              <Grid item>
                <Button
                  disableFocusRipple
                  component={Link}
                  to={RECOVER_PASSWORD}
                  variant="text"
                  color="primary"
                  sx={{
                    padding: 0
                  }}
                >
                  <Typography variant="body2">Reset password</Typography>
                </Button>
              </Grid>
            </Grid>
            <Box my={2}>
              <Button
                disableElevation
                component={Link}
                fullWidth
                to={ONBOARDING}
                size="large"
                variant="contained"
                disabled={loading}
              >
                Login with email
              </Button>
            </Box>
          </form> */}
            <Box mb={2} display="flex" justifyContent="center">
              <Button
                endIcon={<GoogleIcon />}
                sx={{fontSize: 28}}
                size="large"
                variant="contained"
                disableElevation
                onClick={onLoginWithGoogle}
              >
                Login with Google
              </Button>
            </Box>
            <Typography sx={{opacity: 0.5}} align="center" paragraph variant="body2">
              You must have a Google account to procceed
            </Typography>
            <Box display="flex" mt={8} justifyContent="center">
              {legalLinks.map((link, i) => (
                <>
                  <Typography
                    target="_blank"
                    color="secondary"
                    component="a"
                    href={link.a}
                    key={i}
                    variant="body2"
                    letterSpacing={-0.5}
                  >
                    {link.title}
                  </Typography>
                  {i < legalLinks.length - 1 && (
                    <Typography sx={{opacity: 0.5}} mx={0.5} key={i} variant="body2">
                      |
                    </Typography>
                  )}
                </>
              ))}
            </Box>
            {/* <Divider> OR </Divider> */}
            {/* <Box my={2} display="flex" justifyContent="center">
            <Button
              component={Link}
              disableElevation
              to={REGISTER}
              fullWidth
              variant="outlined"
              startIcon={<PersonAddAlt1Icon />}
              color="primary"
              size="large"
            >
              Create new account
            </Button>
          </Box> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{background: blueGradient}}>
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={4000}
        >
          {carouselItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                height: {xs: 650, sm: 850, md: '100vh'},
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignItems: 'center',
                p: {xs: 4, lg: 6}
              }}
            >
              {Math.abs(activeStep - index) <= 2 && (
                <>
                  <Typography
                    variant="h4"
                    fontSize={{xs: 36, md: 40, lg: 48}}
                    paragraph
                    mb={4}
                    lineHeight={1.1}
                    align="center"
                    color="white"
                  >
                    {item.claim}
                  </Typography>
                  <Box width="100%" maxHeight="520px">
                    <img src={item.img} alt={item.claim} width="100%" height="100%" />
                  </Box>
                </>
              )}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          variant="progress"
          sx={{
            width: {xs: '100%', md: '50%'},
            backgroundColor: 'primary.main',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'secondary.main'
            }
          }}
          steps={maxSteps}
          position="bottom"
          activeStep={activeStep}
          nextButton={
            <IconButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <KeyboardArrowRight sx={{color: 'white'}} />
            </IconButton>
          }
          backButton={
            <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft sx={{color: 'white'}} />
            </IconButton>
          }
        />
      </Grid>
    </Grid>
  );
}
