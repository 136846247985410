import {useState, useEffect} from 'react';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {
  Typography,
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  AlertTitle,
  ThemeProvider,
  Button,
  Grid
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import VideoPlayer from 'components/VideoPlayer';
import {useAuthContext} from 'contexts/AuthContext';
import article from 'images/article.svg';
import theme, {darkTheme, headerHeight, whiteGradient, blackGradient} from 'theme/theme';
import Menu from 'views/logged/components/Menu';
import StyledPaper from './components/StyledPaper';

DocumentLayout.propTypes = {
  company: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool
};

export default function DocumentLayout({company, documents, title, dark = false}) {
  const {currentUser} = useAuthContext();
  const [document, setDocument] = useState('');
  let [content, setContent] = useState({md: ''});
  const [seeAsLoggedIn, setSeeAsLoggedIn] = useState(Boolean(currentUser));

  const handleChange = (event) => {
    setDocument(event.target.value);
  };

  useEffect(() => {
    fetch(document.text)
      .then((result) => result.text())
      .then((md) => {
        setContent({md});
      });
  }, [document]);

  return (
    <ThemeProvider theme={dark ? darkTheme : theme}>
      <Menu businessName={company.businessName} />
      {seeAsLoggedIn && (
        <Box backgroundColor={dark ? 'white' : '#232323'} display="flex" justifyContent="center">
          <Box
            alignItems="center"
            display="flex"
            flexDirection={{xs: 'column', md: 'row'}}
            p={1}
            px={3}
          >
            <Typography color={dark ? '#232323' : 'white'}>
              You are logged in as <b>{currentUser.email.substring(0, 8)}</b>...
            </Typography>
            <Button
              variant="contained"
              disableElevation
              size="small"
              color="secondary"
              sx={{py: 1, fontSize: 16, mx: 1, my: 1}}
              onClick={() => {
                setSeeAsLoggedIn(false);
              }}
              endIcon={<TravelExploreIcon fontSize="small" />}
            >
              See public version
            </Button>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          background: dark ? blackGradient : whiteGradient,
          color: dark ? 'white' : 'default',
          minHeight: `calc(100vh - ${headerHeight}px)`,
          p: {xs: 1, md: 3}
        }}
      >
        <Container maxWidth="lg">
          <Typography mt={2} align="center" variant="h4" color="secondary">
            <b>{title}</b>
          </Typography>
          <Typography variant="h2" align="center" paragraph mb={2}>
            {company.businessName}
          </Typography>
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel
              sx={{
                fontSize: 25,
                fontWeight: 'bold',
                lineHeight: 1,
                color: dark ? 'secondary.main' : 'primary.main'
              }}
              id="select-document"
            >
              Select a document
            </InputLabel>
            <Select
              labelId="select-document"
              id="select-document"
              label="Select a document bigger"
              onChange={handleChange}
              value={document}
            >
              {documents.map((document, i) => (
                <MenuItem key={i} value={document}>
                  {document.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {document ? (
            <Box mt={2}>
              {seeAsLoggedIn && (
                <Grid
                  container
                  alignItems="center"
                  direction="row-reverse"
                  p={2}
                  mt={2}
                  sx={{
                    border: 1,
                    borderRadius: '20px 20px 0  0',
                    borderColor: dark ? 'secondary.main' : 'primary.main',
                    color: dark ? 'secondary.main' : 'primary.main',
                    borderBottom: 'none'
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={document.videoTutorial ? 6 : 12}
                    lg={document.videoTutorial ? 7 : 12}
                  >
                    <AlertTitle>
                      <b>What</b> is this document
                    </AlertTitle>
                    <Typography variant="body2" paragraph>
                      {document.whenToUse}
                    </Typography>
                    <AlertTitle>
                      <b>How</b> to use this document
                    </AlertTitle>
                    <Typography variant="body2" paragraph>
                      {document.howToUse}
                    </Typography>
                  </Grid>
                  {document.videoTutorial && (
                    <Grid item xs={12} md={6} lg={5} sx={{pr: {xs: 0, md: 2}}}>
                      <VideoPlayer url={document.videoTutorial} />
                    </Grid>
                  )}
                </Grid>
              )}
              <StyledPaper seeAsLoggedIn={seeAsLoggedIn} dark={dark}>
                <Markdown options={{wrapper: 'article', disableParsingRawHTML: true}}>
                  {content.md
                    .replace('{{businessName}}', company.businessName)
                    .replace('{{companyAddress}}', company.companyAddress)
                    .replace('{{companyIdNumber}}', company.companyIdNumber)
                    .replace('{{emailForLegalPurposes}}', company.emailForLegalPurposes)
                    .replace('{{companyName}}', company.companyName)}
                </Markdown>
              </StyledPaper>
            </Box>
          ) : (
            <Box maxWidth={500} mx="auto" mt={4}>
              <Box maxWidth={300} mx="auto">
                <img src={article} alt="select a document" width="100%" />
              </Box>
              <Typography color="primary" variant="h2" textAlign="center">
                Please, select a document
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
}
