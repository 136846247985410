import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Alert,
  AlertTitle
} from '@mui/material';
import PropTypes from 'prop-types';
import ShareSnackbar from 'components/ShareSnackbar';
import article from 'images/article.svg';
import damn from 'images/damn.svg';
import {blueGradient} from 'theme/theme';
import Coolfetti from 'views/logged/components/Coolfetti';

OnboardingStepsView.propTypes = {
  form: PropTypes.object.isRequired,
  onInputChanged: PropTypes.func.isRequired
};

StyledAlert.propTypes = {
  description: PropTypes.string,
  conclusion: PropTypes.bool,
  title: PropTypes.string
};

function StyledAlert(props) {
  return (
    <Alert
      severity={
        props.conclusion === true ? 'warning' : props.conclusion === undefined ? 'info' : 'success'
      }
      sx={{my: 1}}
    >
      <AlertTitle sx={{fontWeight: 'bold'}} mb={0}>
        {props.conclusion === true
          ? 'Yes, you need to appoint a DPO'
          : props.conclusion === undefined
          ? props.title
          : 'You are fine without a DPO'}
      </AlertTitle>
      {props.description}
    </Alert>
  );
}

export default function OnboardingStepsView({form, onInputChanged}) {
  return (
    <Box
      sx={{
        background: blueGradient,
        color: 'white'
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          columnSpacing={4}
          direction="row-reverse"
          justifyContent="center"
          alignItems="center"
          px={{xs: 1, md: 4}}
          py={6}
        >
          <Grid item container xs={12} md={8} lg={7}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h3">Do I need a DPO?</Typography>
              <Typography>You may be required to hire a Data Protection Officer.</Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <FormControl>
                <FormLabel
                  id="publicAuthority"
                  sx={{fontWeight: 'bold', '&.Mui-focused': {color: 'white'}}}
                >
                  1. Are you a public authority or body?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="publicAuthority"
                  name="publicAuthority"
                  onChange={onInputChanged}
                  value={form.publicAuthority}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                  {form.publicAuthority === 'true' && (
                    <StyledAlert
                      conclusion={true}
                      description="There are some exemptions to this, but you probably do need a data protection
                      officer."
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            {form.publicAuthority === 'false' && (
              <Grid item xs={12} mb={2}>
                <FormControl>
                  <FormLabel
                    id="largeScale"
                    sx={{fontWeight: 'bold', '&.Mui-focused': {color: 'white'}}}
                  >
                    2. Do you process personal data <em>on a large scale?</em>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="largeScale"
                    name="largeScale"
                    onChange={onInputChanged}
                    value={form.largeScale}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value="idk" control={<Radio />} label="I'm not sure" />
                    {form.largeScale === 'true' && <StyledAlert conclusion={true} />}
                  </RadioGroup>
                </FormControl>
                {form.largeScale === 'idk' && (
                  <StyledAlert
                    title="What is 'large scale'?"
                    description="Article 37.1 of the GDPR says that if you 'perform regular and systematic observation of people on a large scale' you need a DPO. The don't give a number, but if you are a small company, you are most likely not doing anything at a large scale."
                  />
                )}
              </Grid>
            )}
            {form.publicAuthority === 'false' && form.largeScale === 'false' && (
              <Grid item xs={12} mb={2}>
                <FormControl>
                  <FormLabel
                    id="specialCategory"
                    sx={{fontWeight: 'bold', '&.Mui-focused': {color: 'white'}}}
                  >
                    3. Do your process data from <em>special categories</em>?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="specialCategory"
                    name="specialCategory"
                    onChange={onInputChanged}
                    value={form.specialCategory}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value="idk" control={<Radio />} label="I'm not sure" />
                  </RadioGroup>
                </FormControl>
                {form.specialCategory === 'true' && <StyledAlert conclusion={true} />}
                {form.specialCategory === 'idk' && (
                  <StyledAlert
                    title="What is 'special category'?"
                    description="That's personal data revealing racial or ethnic origin, political opinions,
                      religious or philosophical beliefs, or trade union membership, and processing
                      of genetic data, biometric data for the purpose of uniquely identifying a
                      natural person, data concerning health or data about a person's sex life or
                      sexual orientation."
                  />
                )}
                {form.publicAuthority === 'false' &&
                  form.largeScale === 'false' &&
                  form.specialCategory === 'false' && (
                    <>
                      <Coolfetti
                        confettiSource={{y: window.innerHeight}}
                        height={document.documentElement.scrollHeight}
                      />
                      <StyledAlert conclusion={false} severity="success" />
                      <ShareSnackbar position="left" />
                    </>
                  )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={5}>
            <img
              src={
                form.publicAuthority === 'true' ||
                form.largeScale === 'true' ||
                form.specialCategory === 'true'
                  ? damn
                  : article
              }
              width="100%"
              height="100%"
              object-fit="cover"
              overflow="hidden"
              alt="login"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
